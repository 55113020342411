import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Form } from '@socialbrothers/components/Containers';
import { Yup } from '@socialbrothers/utils';

import { BuildingTypeCompanyService } from '@Services/BuildingTypeCompany';
import { storageService } from '@Services/StorageService/StorageService';

export const buildingTypeSchema = Yup.object({
  quote: Yup.string(),
  video: Yup.string(),
  UniqueSellingPoints: Yup.mixed(),
  testimonial: Yup.mixed(),
  blogs: Yup.mixed(),
  whitePaper: Yup.mixed(),
});

export const BuildingTypeForm = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const preSubmitFormatter = async (values: any) => {
    if (values.whitePaper && values.whitePaper.file) {
      const response = await storageService.upload(values.whitePaper.file);

      if (response && response[0].id) {
        return {
          ...values,
          whitePaper: {
            id: response[0].id,
          },
        };
      }
    }

    return values;
  };

  return (
    <Form.Resource
      preSubmitFormatter={(values) => {
        return preSubmitFormatter(values);
      }}
      afterFetchFormatter={(model) => {
        return {
          ...model,
          uniqueSellingPoints: model.uniqueSellingPoints ?? undefined,
        };
      }}
      service={BuildingTypeCompanyService}
      label={t('BUILDINGTYPE.SINGLE')}
      id={id}
    >
      <Form.Layout.Field translationKey="BUILDINGTYPE.LABELS.QUOTE">
        <Form.Input.Text name="quote" placeholder={t('BUILDINGTYPE.PLACEHOLDERS.QUOTE')} />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="BUILDINGTYPE.LABELS.VIDEOID">
        <Form.Input.Text name="videoUrl" placeholder={t('BUILDINGTYPE.PLACEHOLDERS.VIDEOID')} />
      </Form.Layout.Field>

      <Form.Layout.Repeater
        source="uniqueSellingPoints"
        entryName={t('BUILDINGTYPE.LABELS.USP')}
        label={t('BUILDINGTYPE.LABELS.USP')}
      >
        <Form.Input.Text name="content" />
      </Form.Layout.Repeater>

      <Form.Layout.Field translationKey="BUILDINGTYPE.LABELS.TESTIMONIAL">
        <Form.Input.Multiline
          name="testimonial.description"
          placeholder={t('BUILDINGTYPE.PLACEHOLDERS.TESTIMONIAL')}
        />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="BUILDINGTYPE.LABELS.TESTIMONIAL_AUTHOR">
        <Form.Input.Text
          name="testimonial.author"
          placeholder={t('BUILDINGTYPE.PLACEHOLDERS.TESTIMONIAL_AUTHOR')}
        />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="BUILDINGTYPE.LABELS.WHITEPAPER">
        <Form.Input.File name="whitePaper" isDocument />
      </Form.Layout.Field>
    </Form.Resource>
  );
};
