import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import { Locale } from '@socialbrothers/constants';
import { AppRoutes, AuthRoutes } from '@socialbrothers/routes';
import { getRoutesFromConfig } from '@socialbrothers/utils';

import useStores from '@Hooks/useStores';
import { AccountService } from '@Services/AccountService';
import { isAdmin, isDealer } from '@Utils/RouteUtils';

import { getAdminRoutes, getDealerRoutes } from './routes';

const PublicRoutes = () => {
  return (
    <Routes>
      {getRoutesFromConfig(AuthRoutes)}

      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

const PrivateRoutes = () => {
  const { data: userMe } = useQuery(['users', 'me'], AccountService.getMe);
  const { i18n } = useTranslation();

  useEffect(() => {
    // TODO: Remove this and let back-end save locale
    const localeLangMap: Record<string, string> = {
      NL: Locale.NL,
      EN: Locale.EN,
    };
    const newLang = userMe?.language ? localeLangMap[userMe.language] : Locale.NL;
    i18n.changeLanguage(newLang);
  }, [i18n, userMe?.language]);

  const routes = isAdmin() ? getAdminRoutes() : isDealer() ? getDealerRoutes() : AuthRoutes;
  const allRoutes = [...AppRoutes, ...routes];

  return (
    <Routes>
      {getRoutesFromConfig(allRoutes, true)}

      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

const RootNavigator = () => {
  const { AuthStore } = useStores();

  return <Router>{AuthStore.isAuthenticated ? <PrivateRoutes /> : <PublicRoutes />}</Router>;
};

export default observer(RootNavigator);
