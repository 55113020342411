import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ReadOnlyField } from '@socialbrothers/components/UI';
import { Spinner } from '@socialbrothers/components/UI';
import { useResource } from '@socialbrothers/hooks';

import { ProjectService } from '@Services/Project';

import { ProjectReadOnlyField } from '../ProjectReadOnlyField/ProjectReadOnlyField';

export const ProjectView = () => {
  const { id } = useParams<{ id: string }>();
  const query = useResource(ProjectService, id);
  const { t } = useTranslation();

  if (!query.isFetched) return <Spinner size={40} />;

  const { data } = query;

  return (
    <>
      <ProjectReadOnlyField field={data.name} text={data.name} translationKey="NAME" />

      <ProjectReadOnlyField
        field={data.building.name}
        text={data.building.name}
        translationKey="BUILDING"
      />

      <ProjectReadOnlyField
        field={data.customer}
        text={data.customer?.name}
        translationKey="CUSTOMER"
      />

      <ProjectReadOnlyField
        field={data.advisor}
        text={`${data.advisor?.firstName} ${data.advisor?.lastName}`}
        translationKey="ADVISOR"
      />

      <ProjectReadOnlyField field={data.notes} text={data.notes} translationKey="NOTES" />

      <ReadOnlyField label={t('PROJECT.LABELS.FILES')}>
        {data.files ? (
          <ul>
            {data.files.map((file: File, idx: number) => {
              return <li key={idx}>{file.name}</li>;
            })}
          </ul>
        ) : (
          <p>
            <i>{t('PROJECT.PLACEHOLDERS.FILES')}</i>
          </p>
        )}
      </ReadOnlyField>

      <ProjectReadOnlyField
        field={data.quotation}
        text={data.quotation}
        translationKey="QUOTATION"
      />

      <ReadOnlyField label={t('PROJECT.LABELS.PROJECT_LINK')}>
        <a
          href={`${process.env.REACT_APP_PORTAL_URL}/${data.customer.publicId}/${t(
            'PROJECT.PLURAL',
          ).toLowerCase()}/${data.id}`}
          rel="noreferrer"
          target="_blank">
          {t('PROJECT.OPEN_PROJECT')}
        </a>
      </ReadOnlyField>
    </>
  );
};
