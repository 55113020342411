import type { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Form } from '@socialbrothers/components/Containers';
import { Button } from '@socialbrothers/components/UI';
import { useTheme } from '@socialbrothers/hooks';
import { Yup } from '@socialbrothers/utils';

import { ERROR_EMAIL_NOT_CONFIRMED } from '@Config';

import styles from './ForgotPasswordForm.module.scss';
import { ForgotPasswordFormProps } from './ForgotPasswordForm.props';

const ForgotPasswordForm = ({ ...props }: ForgotPasswordFormProps) => {
  const [hasSubmitted, setSubmitted] = useState(false);
  const [notVerified, setNotVerified] = useState(false);
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const { authService } = useTheme();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
  });

  const handleSubmit = async (values: any) => {
    if (notVerified) {
      try {
        await authService.resendConfirmation(values.email);
        setSubmitted(true);
      } catch (e) {
        setError(t('VALIDATION.COMMON.UNKNOWN_ERROR'));
      }
    } else {
      try {
        await authService.forgotPassword(values.email);
        setSubmitted(true);
      } catch (e) {
        if ((e as AxiosError).response?.data.code === ERROR_EMAIL_NOT_CONFIRMED) {
          setError(t('AUTHENTICATION.FORGOT_PASSWORD_PAGE.ERROR_EMAIL_UNVERIFIED'));

          setNotVerified(true);
        } else {
          setError(t('VALIDATION.COMMON.UNKNOWN_ERROR'));
        }
      }
    }
  };

  return hasSubmitted ? (
    <div className={styles.ForgotPasswordForm}>
      {notVerified
        ? t('AUTHENTICATION.FORGOT_PASSWORD_PAGE.SUBMISSION_SUCCESSFUL')
        : t('AUTHENTICATION.FORGOT_PASSWORD_PAGE.SUBMISSION_SUCCESSFUL')}

      <div className={styles.FooterLinks}>
        <Link to="/">{t('AUTHENTICATION.FORGOT_PASSWORD_PAGE.BACK')}</Link>
      </div>
    </div>
  ) : (
    <div className={styles.ForgotPasswordForm}>
      <Form.Base
        onSubmit={handleSubmit}
        error={error}
        {...props}
        validationSchema={validationSchema}>
        <Form.Layout.Field translationKey="FORMS.COMMON_LABELS.EMAIL">
          <Form.Input.Text
            name="email"
            placeholder={t('AUTHENTICATION.FORGOT_PASSWORD_PAGE.EMAIL_PLACEHOLDER')}
          />
        </Form.Layout.Field>

        <div className={styles.Footer}>
          <Button block type="submit">
            {notVerified
              ? t('AUTHENTICATION.FORGOT_PASSWORD_PAGE.RESEND_LABEL')
              : t('AUTHENTICATION.FORGOT_PASSWORD_PAGE.SUBMIT_LABEL')}
          </Button>

          <hr className={styles.Seperator} />

          <div className={styles.FooterLinks}>
            <Link to="/">{t('AUTHENTICATION.FORGOT_PASSWORD_PAGE.BACK')}</Link>
          </div>
        </div>
      </Form.Base>
    </div>
  );
};

export default ForgotPasswordForm;
