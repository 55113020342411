import cn from 'classnames';
import { createContext, useCallback, useContext, useState } from 'react';
import { useMemo } from 'react';
import { useQueryClient } from 'react-query';

import { Form } from '@socialbrothers/components/Containers';
import { Locale } from '@socialbrothers/constants';
import { Storage } from '@socialbrothers/helpers';
import { enumToOptions } from '@socialbrothers/utils';

import { AppService } from '@Services/AppService';

import styles from './LanguageSwitcher.module.scss';
import { LanguageSwitcherProps } from './LanguageSwitcher.props';

interface LanguageContext {
  locale: Locale;
  setLocale: (value: Locale) => void;
}

const languageContext = createContext<LanguageContext | null>(null);

export const useLanguage = () => {
  const context = useContext(languageContext);

  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }

  return context;
};

export const LanguageProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentLocale, setCurrentLocale] = useState(Storage.getLocale());
  const queryClient = useQueryClient();

  const setLocale = useCallback(
    (locale: Locale) => {
      if (locale) {
        AppService.setLocale(locale);
        Storage.setLocale(locale);
        queryClient.invalidateQueries();
        setCurrentLocale(locale);
      }
    },
    [queryClient],
  );

  const context = useMemo(() => {
    return { locale: currentLocale, setLocale };
  }, [currentLocale, setLocale]);

  return <languageContext.Provider value={context}>{children}</languageContext.Provider>;
};

const LanguageSwitcher = ({ className }: LanguageSwitcherProps) => {
  const { setLocale, locale } = useLanguage();

  const handleChange = useCallback(
    (values: { locale: Locale }) => {
      setLocale(values.locale);
    },
    [setLocale],
  );

  return (
    <div className={cn(styles.LanguageSwitcher, className)}>
      <Form.Base initialValues={{ locale }} onChange={handleChange}>
        <Form.Input.Select name="locale" options={enumToOptions(Locale, 'LOCALE')} />
      </Form.Base>
    </div>
  );
};

export default LanguageSwitcher;
