import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Form } from '@socialbrothers/components/Containers';
import { Language } from '@socialbrothers/constants';
import { enumToOptions, isCreate as getIsCreate, Yup } from '@socialbrothers/utils';

import { IUser, UserService } from '@Services/User';

import { UserAdminForm } from './internal/UserAdminForm/UserAdminForm';
import { UserDealerForm } from './internal/UserDealerForm/UserDealerForm';
import { UserFormProps } from './UserForm.props';

export const userCreateSchema = Yup.object({
  email: Yup.string().email().required(),
  role: Yup.string().required(),
  language: Yup.string().required(),
});

export const userSchema = Yup.object({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
});

export const BaseUserForm = ({ validationSchema = Yup.object(), children }: UserFormProps) => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const isCreate = getIsCreate(id);

  const combinedSchema = isCreate
    ? userCreateSchema.concat(validationSchema)
    : userSchema.concat(validationSchema);

  return (
    <Form.Resource
      service={UserService}
      label={t('USER.SINGLE')}
      validationSchema={combinedSchema}
      afterFetchFormatter={(user: IUser) => {
        if (user?.roles?.[0]) {
          return { role: user.roles?.[0], ...user };
        }

        return user;
      }}
      preSubmitFormatter={(values) => {
        return { ...values, companyId: values.company.id };
      }}
      id={id}
    >
      <Form.Layout.Field translationKey="USER.LABELS.EMAIL">
        <Form.Input.Text
          name="email"
          placeholder={t('USER.PLACEHOLDERS.EMAIL')}
          disabled={!isCreate}
        />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="USER.LABELS.FIRSTNAME">
        <Form.Input.Text name="firstName" placeholder={t('USER.PLACEHOLDERS.FIRSTNAME')} />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="USER.LABELS.LANGUAGE">
        <Form.Input.Select
          name="language"
          placeholder={t('USER.PLACEHOLDERS.LANGUAGE')}
          options={enumToOptions(Language, 'LANGUAGE')}
        />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="USER.LABELS.LASTNAME">
        <Form.Input.Text name="lastName" placeholder={t('USER.PLACEHOLDERS.LASTNAME')} />
      </Form.Layout.Field>

      {children}
    </Form.Resource>
  );
};

export const UserForm = {
  Base: BaseUserForm,
  Admin: UserAdminForm,
  Dealer: UserDealerForm,
};
